<template>
  <div>
    <TopNavigation />
    <div class="paymentNotification pa-2 mt-16" v-if="this.loading" align="center">
      <h1>Payment of {{ this.orderData.orderPaymentDetails.amount }} Succesful</h1>
      <div class="mt-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Product
                </th>
                <th class="text-left">
                  Quantity
                </th>
                <th class="text-left">
                  Items
                </th>
                <th class="text-left">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderData.cartList" :key="item._id">
                <td>{{ item.productId.productName }} / {{ item.productId.productDescription }}</td>
                <td>{{ item.productBaseQuantity }}</td>
                <td>{{ item.orderQuantity }}</td>
                <td>{{ item.productTotalBill }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-5">Your Order {{ this.orderData.orderSeriesNo }} will be delivered tomorrow morning</p>

        <p>
          For any changes in your order please reach out to:
          <br>{{ this.orderData.partnerName }}<br>
          <a :href="WhatsAppLink()" class="contactLink">{{ this.orderData.partnerMobile }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import partnerServices from '@/services/partnerServices'
import TopNavigation from './static/TopNavigation.vue'

export default {
  name: 'PaymentStatus',
  components: {
    TopNavigation
  },
  props: {
  },
  data () {
      return {
        loading: false,
        orderData: null
      }
  },
  methods: {
    WhatsAppLink(){
      return 'https://api.whatsapp.com/send/?text=Hi&phone=91' + this.orderData.partnerMobile
    }

  },
  async mounted() {
    this.loading = false
    const callBackData = (await partnerServices.razorpayPaymentCallback(this.$route.query)).data

    this.orderData = callBackData.order
    console.log(this.orderData)
    this.loading = true
  }
}
</script>
<style scoped>
.paymentNotification {
  color: #FDFAF1;
}
.contactLink {
  color: #FDFAF1;
  font-size: 22px;
}
</style>
